<template>
  <div class="information">
    <div class="bread">
      <i class="el-icon-arrow-left" @click="goback()"></i>
      会议日程
    </div>
    <div class="content"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
.information {
  color: #fff;
  background: url("../../assets/image2/background.png") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  position: relative;
  .bread {
    height: 5vh;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 5vh;
    text-align: center;
    position: relative;
    i {
      position: absolute;
      left: 10px;
      top: 10px;
      font-weight: 600;
    }
  }
  .content {
    height: 95vh;
    z-index: 999;
    background: url('../../assets/image2/meeting.png') no-repeat;
    background-size: 100% 100%;
  }
  .bg3 {
    position: absolute;
    background: url("../../assets/image2/BG-3.png") no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 120px;
  }
}
</style>
